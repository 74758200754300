<script setup lang="ts">
import type { CmsElementText } from "@shopware-pwa/composables-next";
import { useCmsElementConfig, useUrlResolver } from "#imports";
import { computed, getCurrentInstance, h, Fragment } from "vue";
import type { VNode } from "vue";
import { decodeHTML } from "entities";
import {
  getOptionsFromNode,
  type NodeObject,
} from "@shopware-pwa/cms-base/helpers/html-to-vue/getOptionsFromNode";
import { renderHtml } from "@shopware-pwa/cms-base/helpers/html-to-vue/renderToHtml";

const props = defineProps<{
  content: CmsElementText;
}>();
const context = getCurrentInstance();
const { getConfigValue } = useCmsElementConfig(props.content);

const mappedContent = computed<string>(() => {
  return props.content?.data?.content || getConfigValue("content");
});

const verticalAlignmentClass = computed(() => {
  const verticalAlign = getConfigValue("verticalAlign");
  switch (verticalAlign) {
    case "flex-start":
      return "flex flex-col justify-start";
    case "center":
      return "flex flex-col justify-center";
    case "flex-end":
      return "flex flex-col justify-end";
    default:
      return "";
  }
});

const hasVerticalAlignment = computed(() => {
  return !!verticalAlignmentClass.value;
});

const CmsTextRender = () => {
  const { resolveUrl } = useUrlResolver();

  const config = {
    textTransformer: (text: string) => decodeHTML(text),
    extraComponentsMap: {
      link: {
        conditions(node: NodeObject) {
          return (
            node.type === "tag" &&
            node.name === "a" &&
            !node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: NodeObject, children: VNode[], createElement: typeof h) {
          return createElement(
            "a",
            {
              class:
                "underline text-base font-normal text-primary hover:text-secondary-900",
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            children,
          );
        },
      },
      button: {
        conditions(node: NodeObject) {
          return (
            node.type === "tag" &&
            node.name === "a" &&
            node.attrs?.class?.match(/btn\s?/)
          );
        },
        renderer(node: NodeObject, children: VNode[], createElement: typeof h) {
          let _class = "";
          if (node?.attrs?.class) {
            const btnClass =
              "rounded-md inline-block my-2 py-2 px-4 border border-transparent text-sm font-medium focus:outline-none disabled:opacity-75";

            _class = node.attrs.class
              .replace("btn-secondary", `${btnClass} bg-dark text-white`)
              .replace("btn-primary", `${btnClass} bg-primary text-white`);
          }

          return createElement(
            "a",
            {
              class: _class,
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            children,
          );
        },
      },
      font: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "font";
        },
        renderer(node: NodeObject, children: VNode[], createElement: typeof h) {
          let newStyle = null;
          const styleColor = node?.attrs?.color;
          if (styleColor) {
            const currentStyle = node.attrs?.style ?? "";
            newStyle = `color:${styleColor};${currentStyle}`;
            delete node.attrs?.color;
          }

          return createElement(
            "span",
            {
              style: newStyle,
              ...getOptionsFromNode(node, resolveUrl).attrs,
            },
            children,
          );
        },
      },
      img: {
        conditions(node: NodeObject) {
          return node.type === "tag" && node.name === "img";
        },
        renderer(node: NodeObject, children: VNode[], createElement: typeof h) {
          return createElement(
            "img",
            getOptionsFromNode(node, resolveUrl)?.attrs,
          );
        },
      },
    },
  };

  const rawHtml =
    mappedContent.value?.length > 0
      ? mappedContent.value
      : "<div class='cms-element-text missing-content-element'></div>";

  // Render the content fragments
  const contentFragments = renderHtml(rawHtml, config, h, context, resolveUrl);

  // Ensure we only render the content fragments directly
  const contentToRender = Array.isArray(contentFragments)
    ? contentFragments
    : [contentFragments];

  if (hasVerticalAlignment.value) {
    const classes = `${verticalAlignmentClass.value}`;

    // If the contentToRender is an array with only one element and it's a div, merge attributes
    if (contentToRender.length === 1 && contentToRender[0].type === "div") {
      const existingProps = contentToRender[0].props || {};
      return h(
        "div",
        {
          ...existingProps,
          class: `${existingProps.class || ""} ${classes}`.trim(),
        },
        contentToRender[0].children,
      );
    } else {
      return h("div", { class: classes }, contentToRender);
    }
  } else {
    return h(Fragment, {}, contentToRender);
  }
};
</script>

<template>
  <CmsTextRender />
</template>
